import React from "react";
import LazyLoad from "react-lazyload";
import { graphql } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import "./index.scss";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { DocumentStyle } from "~components/templates/document-style";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section } from "~components/templates/section";
import { FaqStyle } from "~components/templates/faq-style";
import { TableStyle } from "~components/templates/table-style";
import { Interview } from "~components/utils/interview";

type Props = {
  data: GatsbyCloudPlatformPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "AWS・Google Cloud・Azure診断",
          description:
            "パブリッククラウド固有のセキュリティ診断を提供します。構成や設定ミスを洗い出す診断を中心に外部から直接アクセス可能な範囲の診断やクラウドにデプロイされているアプリケーションも加えてのペネトレーションテストなどご希望やご予算に合わせたご提案が可能です。",
        }}
      >
        <div className="CloudPlatform AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop CloudPlatformTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1>AWS・Google Cloud・Azure診断</h1>
                    <p>
                      パブリッククラウド固有のセキュリティ診断を提供します。構成や設定ミスを洗い出す診断を中心に
                      外部から直接アクセス可能な範囲の診断やクラウドにデプロイされているアプリケーションも加えてのペネトレーションテストなどご希望やご予算に合わせたご提案が可能です。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="AWS・Google Cloud・Azure診断" />

          <LazyLoad>
            <Section background="subtle-gray">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>
                    脆弱性はアプリケーションの実装や
                    <br />
                    プラットフォームのみに存在するわけではありません
                  </h2>
                  <p>
                    近年多くの企業でパブリッククラウドやプライベートクラウドを活用しサービスを提供しているという事例を目にします。しかし、これらサービスにおいて設定のミスが起因となるインシデントが近年話題としてあがることがあります。
                    <br />
                    <br />
                    すなわち、アプリケーションやOS、ミドルウェアのセキュリティ対策が万全であっても利用しているクラウド起因で情報漏洩などのインシデントにつながる可能性があるのです。
                  </p>
                  <h2>クラウドの設定ミスが最も大きな脅威</h2>
                  <img
                    className="CloudPlatform_SurveyResultImage"
                    src={data?.survey_result?.publicURL || ""}
                    alt="68%"
                  />
                  <p className="CloudPlatform_VulnInCloudDescription">
                    (ISC)²が公開した
                    <a href="https://www.isc2.org/resource-center/reports/2020-cloud-security-report">
                      2020 CLOUD SECURITY REPORT
                    </a>
                    におけるアンケート調査で、68%の回答者が使用しているクラウドサービスの設定ミスを最も大きな脅威として挙げました。前年度の調査では3番目の脅威だったところから、大きくその認知を高めました。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>診断可能なクラウドサービス</h2>
                  <div className="CloudPlatform_TargetLogosWrapper">
                    <ul className="CloudPlatform_TargetLogos">
                      <li className="CloudPlatform_TargetLogo">
                        <img src={data?.aws?.publicURL || ""} alt="AWS" />
                      </li>
                      <li className="CloudPlatform_TargetLogo">
                        <img
                          src={data?.gcp?.publicURL || ""}
                          alt="Google Cloud"
                        />
                      </li>
                      <li className="CloudPlatform_TargetLogo">
                        <img
                          src={data?.azure?.publicURL || ""}
                          alt="Microsoft Azure"
                        />
                      </li>
                    </ul>
                  </div>
                  <h2>サービス概要</h2>
                  <p>
                    AWS・Google
                    Cloud・Azureなどのパブリッククラウドを利用する中で、責任共有モデルのような提供者と利用者の責任分解の原則に基づき、利用者はコンポーネントが提供する設定を利用用途に合わせて設定しなければなりません。
                    しかし、この設定において不備があると、
                    <span>
                      ユースケース上で意図していない動作ができてしまう場合や、保存しているデータが公開されてしまう恐れ
                    </span>
                    があります。
                    <br />
                    <br />
                    本サービスでは、そのような設定の不備を事前に把握するためにパブリッククラウドが提供するコンポーネント固有の観点についてセキュリティ診断を提供します。
                    <br />
                    <br />
                    AWS・Google Cloud・Azureの主な診断観点と、Flatt
                    Securityが別のサービスとして提供する
                    <a href="/assessment/web_app">Webアプリケーション診断</a>・
                    <a href="/assessment/sp_app">
                      スマートフォンアプリケーション診断
                    </a>
                    ・<a href="/assessment/platform">プラットフォーム診断</a>
                    との診断対象の違いは以下をご覧ください。
                  </p>
                  <br />
                </DocumentStyle>
                <img
                  className="CloudPlatform_ServiceImage"
                  src={data?.cpf?.publicURL || ""}
                  alt="cloud_platform_cpf"
                />
                <DocumentStyle>
                  <p>※上図はAWSを使用している場合の例です。</p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>診断観点(AWS)</h2>
                  <p>
                    通常のアプリケーション診断やプラットフォーム診断とは異なり、パブリッククラウドの提供するコンポーネント単位、またはそれに連動する一連の流れを一つの診断単位としており、コンポーネントの設定検証はもちろん、IAMのリソースへのアクセス制御やイベント通知で駆動するLambdaの診断も行います。
                  </p>
                  <TableStyle>
                    <table>
                      <tbody>
                        <tr>
                          <th> Cognito</th>
                          <td>
                            ユースケースにあったアカウント発行プロセス検証
                            <br />
                            <br />
                            属性の書き込み等の設定検証
                            <br />
                            <br />
                            ID Pool から発行されたIAMのアクセス制御の検証
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <th>S3</th>
                          <td>
                            ユースケースにあったアカウント発行プロセス検証
                            <br />
                            <br />
                            パブリックからのオブジェクト/バケットのアクセス権限の検証
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <th>Dynamo DB</th>
                          <td>
                            Cognito ID Pool を利用したFGAC(Fine-Grained Access
                            Control)の検証
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <th>Lambda</th>
                          <td>
                            イベント通知により動作する Lambda
                            の動的/静的なセキュリティ診断
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </TableStyle>
                  <p>
                    ※この診断観点は一例として表記しており、他のサービスの診断についてもご相談いただけます。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="AWS・Google Cloud・Azure診断の事例インタビュー">
              <AssessmentVoiceList
                slugs={[
                  "tameny",
                  "jiem",
                  "smarthr",
                  "knowledgework",
                  "leaner-technologies",
                ]}
              />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <Section background="brand">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>料金</h2>
                  <h4>
                    ヒアリングとお見積もりを通して最終的な料金を決定させていただきます。
                  </h4>
                  <p>
                    AWS・Google
                    Cloud・Azure診断では、診断内容と規模に応じた都度のお見積りを実施させていただきます。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <AssessmentDocContact />

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>技術ブログ公開中</h2>
                  <p>
                    AWSを題材にクラウド環境のセキュリティに関する技術ブログを公開中です。ぜひご覧ください。
                  </p>
                  <div className="AssessmentCommonBlog_Item">
                    <a href="https://flattsecurity.hatenablog.com/entry/cloud_security_aws_case">
                      <img
                        className="AssessmentCommonBlog_Image"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20201118/20201118110817.png"
                        alt="技術ブログサムネイル「AWS 診断を事例としたクラウドセキュリティ。サーバーレス環境の不備や見落としがちな Cognito の穴による危険性」"
                      />
                      https://flattsecurity.hatenablog.com/entry/cloud_security_aws_case
                    </a>
                  </div>
                  <div className="AssessmentCommonBlog_Item">
                    <a href="https://blog.flatt.tech/entry/lambda_and_serverless_security">
                      <img
                        className="AssessmentCommonBlog_Image"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20220314/20220314204218.png"
                        alt="サーバーレスのセキュリティリスク - AWS Lambdaにおける脆弱性攻撃と対策"
                      />
                      https://blog.flatt.tech/entry/lambda_and_serverless_security
                    </a>
                  </div>
                  <div className="AssessmentCommonBlog_Item">
                    <a href="https://blog.flatt.tech/entry/lambda_library_security">
                      <img
                        className="AssessmentCommonBlog_Image"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20220222/20220222223952.png"
                        alt="Lambdaの落とし穴 - 脆弱なライブラリによる危険性とセキュリティ対策"
                      />
                      https://blog.flatt.tech/entry/lambda_library_security
                    </a>
                  </div>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <AssessmentArticleList
            data={{
              articles: data.allContentfulArticle.nodes,
              category: data.contentfulArticleCategory,
            }}
          />

          <LazyLoad>
            <Section background="subtle-gray">
              <Common2PaneSectionLayout>
                <FaqStyle>
                  <h2>よくある質問</h2>
                  <div>
                    <h3>どのような診断を行いますか？</h3>
                    <p>
                      各種ガイドラインやクラウドベンダーが公表しているベストプラクティスをベースに診断を実施します。
                      診断対象にアプリケーションも含める場合はアプリケーションの動作を踏まえた上での診断を実施することも可能です。
                    </p>
                  </div>
                  <div>
                    <h3>診断手法はどのようなものがありますか？</h3>
                    <p>
                      本ページでは主に設定ミスのチェックを紹介しておりますが、ブラックボックス診断やホワイトボックス診断、ペネトレーションテストなどご予算やご要望に合わせて診断を提案致します。
                    </p>
                  </div>
                  <div>
                    <h3>
                      Webアプリケーション診断など他の診断と組み合わせることは可能ですか？
                    </h3>
                    <p>
                      AWS・Google
                      Cloud・Azure単体での診断も可能ですが、クラウド上で動作しているアプリケーションを含めた診断をおすすめしています。
                    </p>
                  </div>
                  <div>
                    <h3> 費用はどのくらいですか？</h3>
                    <p>
                      診断対象のヒアリングを行い費用を提示いたします。
                      診断のご相談、見積のご依頼はお気軽にお問い合わせください。
                    </p>
                  </div>
                </FaqStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query CloudPlatformPage {
    survey_result: file(
      relativePath: { eq: "assessment/cloud_platform_survey_result.svg" }
    ) {
      publicURL
    }
    aws: file(relativePath: { eq: "assessment/cloud_platform_aws.svg" }) {
      publicURL
    }
    gcp: file(relativePath: { eq: "assessment/cloud_platform_gcp.png" }) {
      publicURL
    }
    azure: file(relativePath: { eq: "assessment/cloud_platform_azure.png" }) {
      publicURL
    }
    cpf: file(relativePath: { eq: "assessment/cloud_platform_cpf.png" }) {
      publicURL
    }
    allContentfulArticle(
      filter: {
        category: { slug: { eq: "cloud_platform" } }
        slug: { ne: "disabled" }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "cloud_platform" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(Component);
